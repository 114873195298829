<template>
  <div class="leyend-last-season" v-if="display">
    <template v-if="type == 1">
      <template v-if="getLeyendIsPastSeason">
        <template v-if="mobile">
          Estadísticas de torneo CL2023.
        </template>
        <template v-else>
          Estadísticas de torneo CL2023.
        </template>
      </template>
    </template>
    <template v-else>
      <template v-if="type == 3">
        <div v-show="false" class="leyend5Games">
          <span class="textBold">NOTA:</span> El filtro por default muestra solo partidos jugados de local para el
          local, y de visitante para el visitante.
        </div>
      </template>
      <template v-else>
        <template v-if="getLeyendIsGamesPastSeason">
          <template v-if="mobile">
            <!--            Se consideran partidos del torneo AP2022.-->
          </template>
          <template v-else>
            <!--            Se consideran partidos del torneo AP2022.-->
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'LeyendLlastSeason',
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters('betsGeneral', ['getLeyendIsPastSeason', 'getLeyendIsGamesPastSeason']),
  },
};
</script>

<style scoped lang="scss">
.leyend5Games {
  @media screen and (max-width: 563px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.textBold {
  font-family: 'Heebo';
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #132839;
}
.leyend-last-season {
  width: auto;
  height: 30px;
  margin: 0 auto;
  margin-top: 21px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #6b6c6c;
  font-family: 'Roboto-Medium';
  &__color {
    font-family: 'Roboto-Black';
    color: #132839;
  }
  @media screen and (max-width: 563px) {
    display: inline-block;
  }
}
</style>
