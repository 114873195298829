import { render, staticRenderFns } from "./LegendLastSeason.vue?vue&type=template&id=3d82d2ac&scoped=true&"
import script from "./LegendLastSeason.vue?vue&type=script&lang=js&"
export * from "./LegendLastSeason.vue?vue&type=script&lang=js&"
import style0 from "./LegendLastSeason.vue?vue&type=style&index=0&id=3d82d2ac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d82d2ac",
  null
  
)

export default component.exports